import React, { useEffect, useState } from 'react';
import { addHours, getDate, getDay, getMonth } from "date-fns"
import { dateSimpleBRWithHour, monthNumber, simpleDateBR, simpleDateUS } from "../../../../../../utils/date";
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Form } from '@unform/web';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import Chart from '../../../../../../components/ChartModule';
import { FaCalendar, FaChartBar, FaChartPie, FaCheck, FaCreditCard, FaFileExcel, FaFilter, FaTicketAlt } from 'react-icons/fa';
import CheckboxInput from '../../../../../../components/Forms/CheckboxInputSearch';
import { useModal } from '../../../../../../hooks/Modal';
import { FiFilter } from 'react-icons/fi';
import { convertToXLSX } from '../../../../../../utils/download';
import { formatReal } from '../../../../../../utils/format';
import SaleProduct from '../../../SalesProducts';
import ReportTable from './modules/ReportTable';
interface IProps {
    sales?: Array<Record<string, any>>,
    salesProducts?: Array<Record<string, any>>,
    students?: Array<Record<string, any>>,
    products?: Array<Record<string, any>>,
    cortesias?: Array<Record<string, any>>,
    cupons?: Array<Record<string, any>>,
}

interface IOption {
    id: string;
    label: string;
    value: string;
}


const convert = (list: Array<Record<string, any>>, group = false) => {

    const items: Array<Array<string>> = [];


    if (group) {
        items.push(['Comprador', 'Nome', 'E-mail', 'Telefone', 'Estado', 'Cidade', 'Deficiência', 'Bruto', 'Juros de parcelamento', 'Taxas', 'Líquido', 'Cupom', 'Cortesia', 'Campo adicional', 'Data de criação', 'Data de pagamento', 'Termo']);
        list?.map(item => {

            items.push([item?.comprador, item?.name, item?.email, item?.phone, item?.state || '', item?.city || '', item?.disability_id || 'Não', formatReal(item?.total), formatReal(item?.fees), formatReal(item?.tax), formatReal(item?.net_total), item?.coupon, item?.cortesia, item?.fields_text, item?.criado_em, item?.pago_em, item?.term]);

        })
    }
    else {
        list?.map(item => {

            items.push([item?.comprador, item?.payment_method_id, item?.payment_status_id, item?.product, item?.haveClientID, item?.name, item?.email, item?.phone, item?.state || '', item?.city || '', item?.disability_id || 'Não', formatReal(item?.total_with_fees), formatReal(item?.fees), formatReal(item?.total), formatReal(item?.tax), formatReal(item?.net_total), item?.coupon, item?.cortesia, item?.fields_text, item?.criado_em, item?.pago_em, item?.term]);

        })
    }


    items?.sort((a, b) => `${a?.[2]}-${a?.[4]}` < `${b?.[2]}-${b?.[4]}` ? -1 : `${a?.[2]}-${a?.[4]}` > `${b?.[2]}-${b?.[4]}` ? 1 : 0)
    items.unshift(['Comprador', 'Forma de pagamento', 'Status de pagamento', 'Produto', 'Transferido?', 'Nome', 'E-mail', 'Telefone', 'Estado', 'Cidade', 'Deficiência', 'Bruto com juros', 'Juros de parcelamento', 'Bruto', 'Taxas', 'Líquido', 'Cupom', 'Cortesia', 'Campo adicional', 'Data de criação', 'Data de pagamento', 'Termo']);


    convertToXLSX(items);

}

const TicketReport: React.FC<IProps> = ({ cupons, sales, cortesias = [], salesProducts = [], students, products = [] }) => {
    const { addModal, removeModal } = useModal();
    const [chartType, setChartType] = useState('bar');
    const [sortBy, setSortBy] = useState('no');
    const [type, setType] = useState('day');
    const [product, setProduct] = useState<Array<string>>(() => {
        const items: Array<string> = []
        products.map(item => {
            items.push(item?._id?.toString());
        })

        return items;


    });
    const [paymentStatus, setPaymentStatus] = useState<Array<string>>([]);
    const [paymentMethod, setPaymentMethod] = useState<Array<string>>([]);


    // Define the target time zone as GMT-3
    const targetTimeZone = 'America/Sao_Paulo'; // Example for GMT-3, adjust as necessary


    const sortByType = [
        { label: 'Data', value: 'brDate' },
        { label: 'Status de pagamento', value: 'payment_status_id' },
        { label: 'Forma de pagamento', value: 'payment_method_id' },
        { label: 'Produto', value: 'product' },
    ]


    const dayType = [
        { label: 'Por dia', value: 'day' },
        { label: 'Por hora', value: 'hour' },
        { label: 'Por mês', value: 'month' },
        { label: 'Por ano', value: 'year' },
    ]

    const payment_status_id = [
        { label: 'Pago', value: 'paid', id: 'paid' },
        { label: 'Aguardando pagamento', value: 'waiting', id: 'waiting' },
    ]

    const payment_method_id = [
        { label: 'Cartão de crédito', value: 'credit_card', id: 'credit_card' },
        { label: 'Boleto', value: 'boleto', id: 'boleto' },
        { label: 'PIX', value: 'pix', id: 'pix' },
        { label: 'Cortesia', value: 'cortesy', id: 'cortesy' },
        { label: 'Gratuidade', value: 'cortesia', id: 'cortesia' },

    ]

    const currentDayType = dayType?.find(item => item?.value === type);

    const currentProduct = products?.find(item => item?._id?.toString() === product);





    const typesBR = {
        day: 'dd/MM/yyyy',
        hour: 'HH',
        year: 'yyyy',
        month: 'MM',
    }

    const typesUSA = {
        day: 'yyyy-MM-dd',
        hour: 'HH',
        year: 'yyyy',
        month: 'MM',
    }

    const paymentMethodInfo = {
        credit_card: 'Cartão de Crédito',
        boleto: 'Boleto',
        pix: 'PIX',
        cortesia: 'Gratuidade',
        courtesy: 'Cortesia',
        cortesy: 'Cortesia'
    }

    const paymentStatusInfo = {
        paid: 'Pago',
        waiting: 'Aguardando pagamento',

    }

    const salesProductsList = salesProducts?.reduce((prev, item) => {

        const userId = item?.client_id_response?._id;
        const student = students?.find(userInfo => userInfo?._id?.toString() === userId)

        if (!student) {
            console.log(userId, item)
        }


        if (product && product?.length >= 0 && product?.[0]) {

            if (product?.findIndex(productHash => productHash === item?.product_id_response?._id?.toString()) < 0) {

                return prev;
            }
        }

        if (paymentStatus && paymentStatus?.length >= 0 && paymentStatus?.[0]) {
            if (paymentStatus?.findIndex(status => status === item?.payment_status_id) < 0) {
                return prev;
            }
        }

        if (paymentMethod && paymentMethod?.length >= 0 && paymentMethod?.[0]) {
            if (paymentMethod?.findIndex(method => method === item?.payment_method_id) < 0) {
                return prev;
            }
        }

        const dateInTargetTimeZone = utcToZonedTime(item.createdAt, targetTimeZone);

        // Format date to dd/MM/yyyy for dateBr
        let brDate = format(dateInTargetTimeZone, typesBR[type]);

        if (sortBy === 'product') {
            brDate = item?.product_id_response?.title
        }

        if (sortBy === 'payment_status_id') {
            brDate = payment_status_id?.find(status => status?.value === item?.payment_status_id)?.label || '';
        }

        if (sortBy === 'payment_method_id') {
            brDate = payment_method_id?.find(method => method?.value === item?.payment_method_id)?.label || '';
        }

        if (sortBy === 'state') {
            brDate = student?.state || 'Não informado';
        }

        if (sortBy === 'city') {
            brDate = student?.city ? `${student?.city} (${student?.state})` : 'Não informado';
        }

        if (sortBy === 'company') {
            brDate = student?.company || 'Não informado';
        }

        if (sortBy === 'disability_type') {
            brDate = student?.disability_type ? student?.disability_type?.trim() : 'Não';
        }

        if (sortBy === 'know_about') {
            brDate = student?.know_about || 'Não informado';
        }

        if (sortBy === 'seller_hash') {
            brDate = item?.seller_hash || 'Sem indicação';
        }

        // Format date to yyyy-MM-dd for dateUS
        const date = format(dateInTargetTimeZone, typesUSA[type]);



        if (sortBy === 'yes') {
            if (prev.findIndex(i => i?.userID === student?._id?.toString()) >= 0) {
                return prev;
            }
        }


        const sale = sales?.find(s => s?.sale_hash === item?.sale_hash);

        let cortesiaInfo = '';

        const produto = products?.find(i => i?._id?.toString() === item?.product_id);

        if (produto && produto?.price < 1) {
            item.payment_method_id = 'cortesia';

        }

        if (sale?.cortesy_hash_id) {

            const cortesia = cortesias?.find(i => i?.hash?.toString() === sale?.cortesy_hash_id
            );

            cortesiaInfo = cortesia?.title || '';

        }




        const saleItem2 = sale?.cart?.find(i => i?.product_id_response?._id === item?.product_id)

        const saleItem = saleItem2 ? saleItem2 : item;



        if (sale?.coupon) {

            const couponItem = cupons?.find(i => i?.hash === sale?.coupon);

            if (couponItem) {

                const priceTag = couponItem?.products?.find(i => i?.product === saleItem?.product_id_response?._id?.toString());

                if (priceTag) {
                    saleItem.price = priceTag?.price;
                    item.price = priceTag?.price;
                }


            }


        }



        const count = salesProducts?.filter(i => i?.sale_hash === sale?.sale_hash && i?.price > 0)?.length;
        const fees = sale?.fees > 0 ? parseInt((sale?.fees / count).toString(), 10) : 0;


        const total_with_fees = saleItem?.price + fees;
        const transaction_tax = parseInt((sale?.transaction_tax / count).toString(), 10);
        const tax = sale?.tax > 0 && item?.price > 0 ? (transaction_tax + parseInt((sale?.tax / count).toString(), 10)) : 0;
        const net_total = item?.price - (tax);


        prev.push({
            comprador: sale?.payer_id_response?.name,
            total_with_fees: total_with_fees,
            total: sale?.total < 1 || item?.price < 1 ? 0 : saleItem?.price,
            fees: sale?.total < 1 || item?.price < 1 ? 0 : fees,
            tax: sale?.total < 1 || item?.price < 1 ? 0 : tax,
            net_total: sale?.total < 1 || item?.price < 1 ? 0 : net_total,

            term: sale?.student?.term || 'Sim',
            pago_em: sale?.approved_at ? dateSimpleBRWithHour(sale?.approved_at) : '',
            criado_em: sale?.createdAt ? dateSimpleBRWithHour(sale?.createdAt) : '',
            fields_text: item?.fields_text, payment_status_id: paymentStatusInfo?.[item?.payment_status_id], payment_method_id: paymentMethodInfo?.[item?.payment_method_id], cortesia: cortesiaInfo, coupon: item?.coupon, date: date, brDate, count: 1, userID: student?._id?.toString(), haveClientID: item?.client_id_response?._id ? 'Sim' : 'Não', product: item?.product_id_response?.title, name: student?.name, email: student?.email, phone: student?.phone, seller_hash: item?.seller_hash, company: student?.company, disability_type: student?.disability_type, know_about: student?.know_about, state: student?.state, city: `${student?.city} (${student?.state})`
        });
        return prev;

    }, []);



    const sortedList = salesProductsList.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);

    const productList = products ? products?.reduce((prev: Array<IOption>, item): Array<IOption> => {
        prev.push({ label: item?.title, value: item?._id?.toString(), id: item?._id?.toString() });
        return prev;
    }, [] as Array<IOption>) : [];



    const preset = [
        {
            specialRules: ['confirmed-cortesy'],
            filters: [{ column: 'payment_status_id', value: 'paid' }],
        }
    ]


    const preparePreset = (data, preset) => {


        let currentData = data;

        preset?.filters?.map(filter => {



            currentData = currentData?.filter(item => item?.[filter?.column] === filter?.value);

        })


        preset?.specialRules?.map(specialTag => {

            if (specialTag === 'confirmed-cortesy') {

                currentData = currentData?.filter(item => (item?.payment_method_id !== 'Cortesia' && item?.payment_method_id !== 'Gratuidade') || (item?.payment_method_id === 'Cortesia' && item?.userID) || (item?.payment_method_id === 'Gratuidade' && item?.userID));


            }

        })

        return currentData;



    }

    return <>



        <ReportTable groupName="Produto" title="inscrições pagas e cortesias retiradas no evento" groupBy={'product'} data={preparePreset(sortedList, {
            specialRules: ['confirmed-cortesy'],
            filters: [{ column: 'payment_status_id', value: 'Pago' }],
        })} />

        <ReportTable groupName="Produto" title="inscrições pagas e cortesias gerais no evento" groupBy={'product'} data={preparePreset(sortedList, {
            specialRules: [],
            filters: [{ column: 'payment_status_id', value: 'Pago' }],
        })} />


        <ReportTable groupName="Produto" title="Todas as inscrições pagas e aguardando" groupBy={'product'} data={preparePreset(sortedList, {
            specialRules: [],
            filters: [],
        })} />


        {/* Listar os produtos e valores relativos

?
[Exportar planilha]
[Excel] - Categoria, QTD, dados de valor
[Excel] - Total
 
Pagos, aguardando e cortesias retiradas no evento?
[Exportar planilha]
[Excel] - Categoria, QTD, dados de valor
[Excel] - Total

Pagos, aguardando e todas as cortesias no evento?
[Exportar planilha]
[Excel] - Categoria, QTD, dados de valor
[Excel] - Total


Produto, Pago, aguardando, cortesias, não cortesias


*/}



    </>


}

export default TicketReport;